import React from "react";
import { List, Datagrid, TextField, Filter, TextInput, SearchInput, useTranslate } from "react-admin";

import { makeStyles, Chip } from '@material-ui/core';

// const DataFilter = (props) => (
//     <Filter {...props}>
//         <TextInput label="Search" source="q" alwaysOn />
//     </Filter>
// );



const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        hiddenOnSmallScreens: (_a = {
                display: 'table-cell'
            },
            _a[theme.breakpoints.down('md')] = {
                display: 'none',
            },
            _a),
    });
});


const DataFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput label="Type" source="serviceFamily" defaultValue="Compute" />
    </Filter>
);

export const AzureList = (props) => (
    <List filters={<DataFilter />} {...props}
    bulkActionButtons={false}
    ExportButton={false}>
        <Datagrid>
            <TextField source="serviceName" />
            <TextField source="serviceFamily" />
            <TextField source="currencyCode" />
            <TextField source="retailPrice" />
            <TextField source="location" />
            <TextField source="productName" />
            <TextField source="skuName" />
            <TextField source="unitOfMeasure" />
            <TextField source="tierMinimumUnits" />
            <TextField source="type" />
            <TextField source="isPrimaryMeterRegion" />
            <TextField source="armSkuName" />
        </Datagrid>
    </List>
);
