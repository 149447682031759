// in App.js
import React from 'react';
import { Admin, Resource, ListGuesser } from 'react-admin';
// import drfProvider from "ra-data-django-rest-framework";
import NotFound from './helpers/NotFound';
import azure from './azure';
import Dashboard from "./Dashboard";
import fakeDataProvider from 'ra-data-fakerest';

// import jsondata from './data/example.json';

// const dataProvider = fakeDataProvider({
//     datatable: [
//         {
//             id: 1,
//             name: 'Html',
//             desc: 'Learn HTML For Beginners With Free Book ',
//             url: 'https://goalkicker.com/HTML5Book/',
//         },
//         {
//             id: 2,
//             name: 'CSS',
//             desc: 'Learn CSS For Beginners With Free Book',
//             url: 'https://goalkicker.com/CSSBook/',
//         },
//         {
//             id: 3,
//             name: 'Javascript',
//             desc: 'Learn Javascript For Beginners With Free Book',
//             url: 'https://goalkicker.com/JavaScriptBook/',
//         },
//     ],
// });

const data = require('./data/azure-instances.json');
const dataProvider = fakeDataProvider({ azure: data.Items });


const App = () => (
    <Admin
        // dashboard={Dashboard}
        catchAll={NotFound}
        dataProvider={dataProvider}
    >
        <Resource name='azure' {...azure} />
    </Admin>
);

export default App;
